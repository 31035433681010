import axios from 'axios';

async function getBrands(filter = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`brands`, { ...filter })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

export {
    getBrands
}