<template>
  <div style="background-color: rgb(243 243 243)">
    <v-container>
      <div class="search-loader" v-if="loading">
        <v-progress-circular
          indeterminate
          color="red"
          size="200"
          class="text-center"
        >
          <img width="75%" :src="getCompanyLogoURL(true)" alt="" />
        </v-progress-circular>
      </div>
      <v-row class="justify-center pt-15 pb-10">
        <v-col class="col-12">
          <div class="row align-end">
            <div class="col-12 col-md-10">
              <h2 class="text-h4 mb-5">
                {{ $t("ReservationConfirmation") }}
              </h2>
              <h3>
                {{ $t("ViewReservationInformationAndConfirm") }}
              </h3>
            </div>
            <div class="col-12 col-md-2 justify-end d-flex">
              <v-btn
                @click="confirmReservation()"
                color="success"
                depressed
                class=" font-weight-bold rounded-0 reserve-btn"
              >
                <v-icon> mdi-checkbox-marked-circle-outline </v-icon>
                {{ $t("ConfirmReservation") }}
              </v-btn>
              <v-btn
                v-if="cardDataLoaded"
                @click="print()"
                color="secondary"
                depressed
                class=" ml-2 mr-2 font-weight-bold rounded-0 reserve-btn"
              >
                <v-icon> mdi-printer </v-icon>
                {{ $t("Print") }}
              </v-btn>
            </div>
          </div>
          <v-card id="print" class="mt-4">
            <div class="row">
              <div class="col-12 col-md-8">
                <v-card-text>
                  <div
                    v-if="!cardDataLoaded"
                    class="d-flex justify-center row pa-5"
                  >
                    <v-progress-circular
                      indeterminate
                      color="red"
                      size="100"
                      class="text-center"
                    >
                      <img width="75%" :src="getCompanyLogoURL(true)" alt="" />
                    </v-progress-circular>
                  </div>
                  <div v-if="cardDataLoaded" class="row">
                    <div class="col-12 text-body-1">
                      <h3>{{ $t("YourInformation") }}</h3>
                      <v-divider class="mb-4"></v-divider>
                      <p v-if="customer.email" class="pl-2 pr-2">
                        <v-icon>mdi-email</v-icon>
                        {{ customer.email }}
                      </p>
                      <p>
                        <span class="d-inline-block pl-2 pr-2 border-left-1">
                          <v-icon>mdi-emoticon-happy-outline</v-icon>
                          {{ customer.name }}
                        </span>
                        <span class="d-inline-block pl-2 pr-2">
                          <v-icon>mdi-phone</v-icon>
                          {{customer.phone_country_code}}{{ customer.phone }}
                        </span>
                      </p>
                      <p
                        v-if="customer.notes"
                        class="font-weight-bold mb-0 pl-2 pr-2"
                      >
                        <v-icon>mdi-comment</v-icon>
                        {{ $t("Notes") }}
                      </p>
                      <p v-if="customer.notes" class="black--text font-italic">
                        {{ customer.notes }}
                      </p>
                    </div>
                    <div class="col-12 text-body-1">
                      <h3>{{ $t("ReservationInformation") }}</h3>
                      <v-divider class="mb-4"></v-divider>
                      <p>
                        <v-icon small>mdi-arrow-left</v-icon>
                        {{ $t("ReceivingAtDate") }}
                        <strong>
                          {{ dateHelpers.formatDate(filter.from) }}
                          {{ dateHelpers.formatTime(filter.from) }}
                        </strong>
                        {{ $t("InLocation") }}
                        <strong>
                          {{ pickupLocation.name }},
                          {{ pickupLocation.country.name }}
                        </strong>
                      </p>
                      <p>
                        <v-icon small>mdi-arrow-right</v-icon>
                        {{ $t("DeliveringAtDate") }}
                        <strong>
                          {{ dateHelpers.formatDate(filter.to) }}
                          {{ dateHelpers.formatTime(filter.to) }}
                        </strong>
                        {{ $t("InLocation") }}
                        <strong>
                          {{ deliveryLocation.name }},
                          {{ deliveryLocation.country.name }}
                        </strong>
                      </p>
                      <h3>{{ $t("ApplyCoupon") }}</h3>
                      <v-divider class="mb-4"></v-divider>
                      <div style="margin-bottom: 20px;">
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="couponCode"
                              :label="$t('Enter Coupon Code')"
                              :placeholder="$t('Enter Coupon Code')"
                              outlined
                              dense
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-btn
                              color="primary"
                              @click="applyCoupon"
                              :disabled="isLoading"
                              depressed
                              hide-details
                              class=" rounded-0"
                            >
                            {{$t("Apply")}}
                              <v-progress-circular
                                v-if="isLoading"
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row v-if="couponApplied">
                          <v-col cols="12">
                            <v-alert type="success" outlined>
                              {{ $t("Coupon applied successfully") }}
                            </v-alert>
                          </v-col>
                        </v-row>
                        <v-row v-if="couponError">
                          <v-col cols="12">
                            <v-alert type="error" outlined>
                              {{ couponError }}
                            </v-alert>
                          </v-col>
                        </v-row>
                      </div>
                      <v-divider></v-divider>
                      <p class="mt-2 mb-1 text-h6">
                        <strong class="ml-1 mr-1 font-weight-bold"> {{ $t('DailyPrice') }}: </strong>
                        <span class="text-underline">
                          {{ Number(this.vehicle.rental_price_per_day).toFixed(2) }} $US
                        </span>
                      </p>
                      <p class="mb-1 text-h6">
                        <strong class="ml-1 mr-1 font-weight-bold"> {{ $t('TotalPrice') }}: </strong>
                        <span class="text-underline">
                          {{ Number(this.vehicle.rental_price).toFixed(2) }} $US ( {{ numberOfDays }} {{ $t('DayOrDays') }} )
                        </span>
                        <div v-show="couponApplied">
                        <p class="mb-1 text-h6">
                          <strong class="ml-1 mr-1 font-weight-bold"> {{ $t('Discount') }}: </strong>
                          <span class="text-underline">
                            {{ Number(coupon_discount_value).toFixed(2) }} $US 
                          </span>
                        </p>
                        <p class="mb-1 text-h6">
                          <strong class="ml-1 mr-1 font-weight-bold"> {{ $t('Final') }}: </strong>
                          <span class="text-underline">
                            {{ Number(final_rental_price).toFixed(2) }} $US ( {{ numberOfDays }} {{ $t('DayOrDays') }} )
                          </span>
                        </p>
                        </div>
                        <div>
                          <strong
                            class="
                              d-inline-flex
                              align-center
                              warning--text
                              text-body-1
                              font-weight-bold
                            "
                          >
                            <v-icon class="ml-2 mr-2" small color="warning"
                              >mdi-alert</v-icon
                            >
                            {{ $t("PricesMightBeSubjectToTaxes") }}
                          </strong>
                        </div>
                      </p>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions v-if="cardDataLoaded">
                  <v-spacer></v-spacer>
                  <v-btn
                    id="cancel_btn"
                    @click="cancel()"
                    color="secondary"
                    depressed
                    class=" rounded-0 reserve-btn"
                  >
                    <v-icon> mdi-cancel </v-icon>
                    {{ $t("Cancel") }}
                  </v-btn>
                </v-card-actions>
              </div>
              <v-divider vertical></v-divider>
              <div class="col-12 col-md-4">
                <v-card-text>
                  <div
                    v-if="!cardDataLoaded"
                    class="d-flex justify-center row pa-5"
                  >
                    <v-progress-circular
                      indeterminate
                      color="red"
                      size="100"
                      class="text-center"
                    >
                      <img width="75%" :src="getCompanyLogoURL(true)" alt="" />
                    </v-progress-circular>
                  </div>
                  <div v-if="cardDataLoaded" class="row">
                    <div class="col-12 text-body-1">
                      <h3>{{ $t("VehicleInformation") }}</h3>
                      <v-divider class="mb-4"></v-divider>
                      <v-carousel
                        id="vehicle_carousel"
                        v-if="vehicle.photos"
                        :show-arrows="false"
                        height="200"
                      >
                        <v-carousel-item
                          v-for="photo in JSON.parse(vehicle.photos)"
                          :key="photo.id"
                        >
                          <async-image :source-link="photo"></async-image>
                        </v-carousel-item>
                      </v-carousel>
                      <h3 class="text-body-2 mt-2">
                        {{ vehicle.brand.name }} / {{ vehicle.model.name }}
                      </h3>
                      <v-divider dark></v-divider>
                      <p
                        v-if="vehicle.num_of_seats"
                        class="mb-1 mt-1 text-body-2"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on">
                              mdi-car-seat
                            </v-icon>
                            {{ vehicle.num_of_seats }}
                          </template>
                          <span>{{ $t("NumberOfSeats") }}</span>
                        </v-tooltip>
                      </p>
                      <p
                        v-if="vehicle.num_of_packages"
                        class="mb-1 text-body-2"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on">
                              mdi-bag-checked
                            </v-icon>
                            {{ vehicle.num_of_packages }}
                          </template>
                          <span> {{ $t("NumberOfPackages") }} </span>
                        </v-tooltip>
                      </p>
                      <p v-if="vehicle.type" class="mb-1 text-body-2">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on">
                              mdi-car-select
                            </v-icon>
                            {{ $t(vehicle.type) }}
                          </template>
                          <span> {{ $t("VehicleType") }} </span>
                        </v-tooltip>
                      </p>
                      <p v-if="vehicle.transmission" class="mb-1 text-body-2">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-bind="attrs" v-on="on">
                              mdi-car-wrench
                            </v-icon>
                            {{ $t(vehicle.transmission) }}
                          </template>
                          <span> {{ $t("VehicleTransmission") }} </span>
                        </v-tooltip>
                      </p>
                    </div>
                  </div>
                </v-card-text>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import AsyncImage from "../../components/Util/AsyncImage";
import { mapGetters } from 'vuex';
import { showVehicle } from "../../api/cars";
import { reserve, applyCoupon } from "../../api/reservation";

export default {
  components: {
    AsyncImage,
  },
  data() {
    return {
      loading:          false,
      cardDataLoaded:   false,
      vehicle:          {},
      filter: {
        from:           new Date(),
        to:             new Date(Vue.dateHelpers.addDaysToDate(new Date(), 1)),
      },
      pickupLocation:   {},
      deliveryLocation: {},
      customer:         {},
      company:          null,
      currency_id:      null,
      exchange_rate:    0,
      coupon: null,
      couponCode: "",
      isLoading: false,
      couponApplied: false,
      couponError: null,
    };
  },
  computed: {
    numberOfDays() {
      let { from, to } = this.filter;
      return Vue.dateHelpers.differenceInDays(from, to);
    },
    ...mapGetters({
      profile: "profile/getProfile",
    }),
    coupon_discount_value() {
      let discount = 0;

      if (this.coupon && this.couponApplied) {
        discount = (this.vehicle.rental_price * this.coupon.discount_percent / 100);
      }

      return discount;
    },
    final_rental_price() {
      let price = this.vehicle.rental_price;

      if (this.coupon && this.couponApplied) {
        price = price - (price * this.coupon.discount_percent / 100);
      }

      return price;
    }
  },
  methods: {
    confirmReservation() {
      this.loading = true;
      let companyUSDCurrency = this.vehicle.company.currencies.filter(
        (currency) => currency.iso == "USD"
      )[0];

      let exchange_rate = companyUSDCurrency.exchange_rate.rate;

      let data = {
        currency_id: companyUSDCurrency.id,
        exchange_rate: exchange_rate,
        customer: { ...this.customer },
        from:  new Date(this.filter.from).toISOString().slice(0, 19).replace('T', ' '),
        to: new Date(this.filter.to).toISOString().slice(0, 19).replace('T', ' '),
        car_id: this.vehicle.id,
        coupon: this.couponApplied ? this.coupon.coupon : null,
        sending_address:
          this.pickupLocation.name +
          ", " +
          this.pickupLocation.country.name,
        receiving_address:
          this.deliveryLocation.name +
          ", " +
          this.deliveryLocation.country.name,
        language: this.$secureStorage.getItem("locale") || "ar",
      };
      reserve(data)
        .then((resp) => {
          if (resp.status == 201) {
            let message = this.$t("ThankYouForReservation");
            this.$root.$emit("notify-success", message);
            this.goTo("Home");
          } else {
            let message = this.$t("ErrorFetchingData");
            this.$root.$emit("notify-error", message);
          }
          this.loading = false;
        })
        .catch((err) => {
          let message = this.$t("ErrorFetchingData");
          this.$root.$emit("notify-error", message);
          this.loading = false;
        });
    },
    async applyCoupon() {
      this.isLoading = true;
      this.couponApplied = false;
      this.couponError = null;

      try {
        if (!this.couponCode || this.couponCode === "") {
          throw new Error(this.$t("Enter coupon code first"));
        }
        const response = await applyCoupon(this.vehicle.company.id, this.couponCode);

        if (!response || response.length == 0) {
          throw new Error(this.$t("Coupon not found"));
        }
        
        this.coupon = response;
        this.couponApplied = true;
        this.couponError = null;

      } 
      catch (error) {
        this.couponError = error.message;
        this.couponApplied = false;
        this.coupon = null;
      } 
      finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$secureStorage.removeItem("reserveFilter");
      this.$router.go(-1);
    },
    async print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      var htmlDir = 'rtl';
      if (this.$secureStorage.getItem("locale")) {
        htmlDir = this.$secureStorage.getItem("locale") == "ar" ? "rtl" : "ltr";
      }

      let docLogo = this.getCompanyLogoURL(true);

      const htmlHeader = `
      <div class="row" style="align-items:center;text-align:center;">
        <div class="col-2 pd-sm">
          <img height="70" src="${docLogo}" alt="DevBay">
        </div>
        <div class="col-7">
          <h3>${this.$t("ReservationInformation")}</h3>
        </div>
        <div class="col-3">
          <p class="no-gutters">${this.company.name}</p>
          <p class="no-gutters">${this.company.address}</p>
          <p class="no-gutters">${this.company.description}</p>
        </div>
      </div>
      `;

      const htmlFooter = `
        <div class="col-12" style="text-align:center;margin-top:100px;">
          <h4>${ this.$t('Note')  }</h4>
          <h5>${ this.$t('ThisReservationRequestIsNotConfirmedYet') }</h5>
        </div>
      `;

      WinPrint.document.write(`<!DOCTYPE html>
        <html dir="${htmlDir}">
          <head>
            ${stylesHtml}
            <style>
              body {background:white;} 
              #cancel_btn {display:none;} 
              #vehicle_carousel {display:none;}
            </style>
          </head>
          <body>
            ${htmlHeader}
            ${prtHtml}
            ${htmlFooter}
          </body>
        </html>`);

      WinPrint.document.close();

      setTimeout(() => {
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
    goTo(routeName) {
      var searchObj = Vue.urlHelper.extractQuery();
      if (searchObj["company_id"]) {
        this.$router.push({name: routeName, query: {company_id: searchObj["company_id"]}});
      } else {
        this.$router.push({name: routeName});
      }
    }
  },
  mounted() {

    let reserveFilter     = JSON.parse(this.$secureStorage.getItem("reserveFilter"));
    let filter            = reserveFilter
    this.pickupLocation   = filter.pickupLocation;
    this.deliveryLocation = filter.deliveryLocation;

    this.filter = {
      from: filter.from,
      to: filter.to,
    };

  },
  async created() {
    if (!this.$route.params.id) {
      let message = this.$t("MissingReservationInformation");
      this.$root.$emit("notify-error", message);
      this.$router.go(-1);
    }

    let reserveFilter     = JSON.parse(this.$secureStorage.getItem("reserveFilter"));
    let filter            = reserveFilter
    this.pickupLocation   = filter.pickupLocation;
    this.deliveryLocation = filter.deliveryLocation;

    this.filter = {
      from: filter.from,
      to: filter.to,
    };

    const vehicleResponse = await showVehicle(this.$route.params.id, this.filter);
    this.vehicle = vehicleResponse.data;
    this.company = this.vehicle.company;
    
    if (!this.profile.length) {
      await this.$store.dispatch("profile/show");
      this.customer = {
        ...this.profile
      }
    }
    setTimeout(() => {
      this.cardDataLoaded = true;
    }, 500);
  },
};
</script>

<style lang="scss">
.border-left-1 {
  border-left: 1px solid lightgrey;
}
.search-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
}
</style>