<template>
  <div>
    <v-container
      fluid
    >
      <v-row>
        <v-col cols="12">
          <img src="../../assets/car-search-banner.svg" class="banner">
        </v-col>
      </v-row>
      <div id="searchPanel">
        <v-row>
          <v-col class="col-12 col-md-4 pt-0">
            <div class="filter-panel">
              <div class="filter-panel-header">
                <span>
                    <v-icon>mdi-filter-outline</v-icon>
                    {{ $t("ReservationInformation") }}
                  </span>
              </div>
              <div class="filter-panel-content-container">
                <v-form ref="filterForm">
                  <div class="filter-panel-content">
                    <div class="filter-panel-content-header">
                      <v-icon>mdi-calendar</v-icon>
                      {{ $t("ReservationDate") }}
                    </div>
                    <v-row>
                      <v-col>
                        <v-datetime-picker
                          @change="onFilterFieldChange"
                          v-model="filter.from"
                          :label="$t('ReceivingDate')"
                          :clearText="$t('Cancel')"
                          :okText="$t('Confirm')"
                          :textFieldProps="{
                            class: 'rounded-0',
                            appendIcon: 'mdi-calendar',
                            solo: true,
                            hideDetails: 'auto',
                            rules: [filterRules.required],
                          }"
                          :datePickerProps="{
                            min: dateHelpers.formatDate(new Date()),
                          }"
                          :timePickerProps="{
                          }"
                        >
                          <template v-slot:dateIcon>
                            <v-icon>mdi-calendar</v-icon>
                          </template>
                          <template v-slot:timeIcon>
                            <v-icon>mdi-clock</v-icon>
                          </template>
                        </v-datetime-picker>
                      </v-col>
                      <v-col>
                        <v-datetime-picker
                        @change="onFilterFieldChange"
                        v-model="filter.to"
                        :label="$t('DeliveringDate')"
                        :clearText="$t('Cancel')"
                        :okText="$t('Confirm')"
                        :textFieldProps="{
                          vModel: filter.to,
                          class: 'rounded-0',
                          appendIcon: 'mdi-calendar',
                          solo: true,
                          hideDetails: 'auto',
                          rules: [filterRules.required],
                        }"
                        :datePickerProps="{
                          min: dateHelpers.formatDate(filter.from),
                        }"
                        :timePickerProps="{
                        }"
                      >
                        <template v-slot:dateIcon>
                          <v-icon>mdi-calendar</v-icon>
                        </template>
                        <template v-slot:timeIcon>
                          <v-icon>mdi-clock</v-icon>
                        </template>
                        </v-datetime-picker>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="filter-panel-content">
                    <div class="filter-panel-content-header">
                      <v-icon>mdi-map</v-icon>
                      {{ $t("ReceivingLocation") }}
                    </div>
                    <v-row>
                      <v-col>
                        <cities-autocomplete
                          ref="receivingLocationRef"
                          :attrs="{
                            appendIcon: 'mdi-map',
                            hideDetails: 'auto',
                            label: $t('ReceivingLocation'),
                            placeholder: $t('SearchLocation'),
                            rules: [filterRules.required],
                            value: this.pickupLocation,
                          }"
                          @city-selected="receivingLocationSelected"
                        >
                        </cities-autocomplete>
                        <v-checkbox
                          @click="onDeliverToDifferentLocationChange"
                          v-model="deliverToDifferentLocation"
                          :label="$t('DeliverToDifferentLocation')"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col v-show="deliverToDifferentLocation">
                        <cities-autocomplete
                          v-show="deliverToDifferentLocation"
                          :attrs="{
                            appendIcon: 'mdi-map',
                            hideDetails: 'auto',
                            label: $t('DeliveringLocation'),
                            placeholder: $t('SearchLocation'),
                            rules: deliverLocationRules,
                            value: this.deliveryLocation,
                          }"
                          @city-selected="deliveringLocationSelected"
                        >
                        </cities-autocomplete>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="filter-panel-content" v-show="showCompanyFilter">
                    <div class="filter-panel-content-header">
                      <v-icon>mdi-domain</v-icon>
                      {{ $tc("Company", 2) }}
                    </div>
                    <v-row >
                      <v-col>
                        <v-select
                          v-model="filter.companies"
                          :items="companies"
                          item-text="name"
                          item-value="id"
                          :label="$tc('Company', 2)"
                          :menu-props="{ offsetY: true }"
                          multiple
                          hideDetails
                          class="rounded-0"
                          solo
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0" color="rgb(79, 125, 156)" class="">
                              <span>
                                {{ item.name }}
                              </span>
                            </v-chip>
                            <span v-if="index === 1" class="black--text">
                              (+{{ filter.companies.length - 1 }})
                            </span>
                          </template>
                          <template slot="item" slot-scope="data">                  
                              <async-image
                                style="background: rgba(255, 255, 255, 0.1)"
                                v-if="data.item.logo"
                                :source-link="JSON.parse(data.item.logo)[0]"
                                :image-attrs="{
                                  aspectRatio: '1.331',
                                  contain: true,
                                  width: '50px'
                                }"
                              >
                              </async-image>
                              {{ data.item.name }}
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="filter-panel-content">
                    <v-row>
                      <v-col>
                        <v-range-slider
                          @change="onFilterFieldChange"
                          v-model="filter.price_range"
                          :label="$t('Price')"
                          :min="0"
                          :max="maxPrice"
                          :step="50"
                          :thumb-label="true"
                          color="#000"
                          prepend-icon="mdi-credit-card"
                          track-color="#000 lighten-3"
                          hideDetails
                          class="rounded-0"
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <div class="filter-panel-content">
                    <v-row>
                      <v-col>
                        <v-text-field
                          @change="onFilterFieldChange"
                          v-model="filter.num_of_seats"
                          :label="$t('NumberOfSeats')"
                          type="Number"
                          color="#000"
                          prepend-icon="mdi-car-seat"
                          hideDetails
                          solo
                          class="rounded-0"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          @change="onFilterFieldChange"
                          v-model="filter.num_of_packages"
                          :label="$t('NumberOfPackages')"
                          type="Number"
                          color="#000"
                          prepend-icon="mdi-bag-checked"
                          hideDetails
                          solo
                          class="rounded-0"
                        />  
                      </v-col>
                    </v-row>
                  </div>
                  <div class="filter-panel-content">
                    <v-row>
                      <v-col>
                        <v-select
                          clearable
                          @change="onFilterFieldChange"
                          v-model="filter.type"
                          :label="$t('VehicleType')"
                          :items="carTypes"
                          color="#000"
                          prepend-icon="mdi-car-select"
                          hideDetails
                          solo
                          class="rounded-0"
                        >
                          <template slot="selection" slot-scope="data">
                            {{ $t(data.item) }}
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ $t(data.item) }}
                          </template>
                        </v-select>
                      </v-col>
                      
                    </v-row>
                  </div>
                  <div class="filter-panel-content">
                    <v-row>
                      <v-col>
                        <v-select
                          clearable
                          @change="onFilterFieldChange"
                          v-model="filter.transmission"
                          :label="$t('VehicleTransmission')"
                          :items="carTransmissions"
                          color="#000"
                          prepend-icon="mdi-car-wrench"
                          hideDetails
                          solo
                          class="rounded-0"
                        >
                          <template slot="selection" slot-scope="data">
                            {{ $t(data.item) }}
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ $t(data.item) }}
                          </template>
                        </v-select>
                      </v-col>
                      
                    </v-row>
                  </div>
                  <div class="filter-panel-content">
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="selectedBrand"
                          clearable
                          :items="carBrands"
                          hide-selected
                          item-text="name"
                          item-value="id"
                          :label="$t('VehicleBrand')"
                          prepend-icon="mdi-car-convertible"
                          return-object
                          @change="onBrandFilterChange"
                          color="#000"
                          hideDetails
                          solo
                          class="rounded-0"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="filter-panel-content">
                    <v-row>
                      <v-col>
                        <v-btn
                          class="mt-9 mb-2 pa-5  rounded-0 elevation-5 search-submit-btn"
                          block
                          depressed
                          @click="setUrlQueryParam()"
                          small
                          color="#000"
                        >
                          <span style="color: #fff;">{{ $t("Search") }}
                            <v-icon small>mdi-magnify</v-icon>
                          </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </div>
            </div>
          </v-col>

          <v-col class="col-12 col-md-8 pt-0 cars-list">
            <v-alert dense color="#EEF0EB" class="rounded-0 pa-0">
              <v-row style="padding: 20px;">
                <v-col cols="12" md="5">
                  <v-select
                    v-model="filter.sortBy"
                    class="mr-4 ml-4"
                    solo
                    hideDetails
                    :items="sortColumns"
                    item-text="text"
                    item-value="value"
                    :label="$t('OrderBy')"
                    :menu-props="{ offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="5">
                  <v-select
                    v-model="filter.sortDir"
                    solo
                    hideDetails
                    class="mr-4 ml-4"
                    :items="sortDirections"
                    item-text="text"
                    item-value="value"
                    :label="$t('Direction')"
                    :menu-props="{ offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                    
                    color="#FA8301"
                    style="width: 100%; margin-top: 5px"
                    @click="setUrlQueryParam()"
                  >
                    {{ $t("Sort") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-alert>
            <div class="search-loader" v-if="apiCall.loading">
              <devbey-logo-loader></devbey-logo-loader>
            </div>
            <v-alert
              dense
              type="error"
              v-if="apiCall.error"
              class=" mb-7 rounded-0"
            >
              {{ apiCall.message }}
            </v-alert>
            <v-alert
              dense
              type="info"
              color="warning"
              v-if="searchedVehiclesByDate && filter.from && filter.to"
              class=" mb-7 rounded-0 darken-1"
            >
              {{ $t("ShowingVehiclesBetween") }}
              <strong>{{ filter.from }}</strong> {{ $t("And") }}
              <strong>{{ filter.to }}</strong>
            </v-alert>
            <v-subheader
              :inset="isMobile"
              v-if="!vehicles.length"
              class=""
            >
              {{ $t("NoVehiclesFound") }}
            </v-subheader>
            <v-row class="">
              <v-col
                cols="12"
                sm="6"
                lg="4"
                v-for="(vehicle, index) in vehicles"
                :key="index"
              >
                <v-col cols="12" class="vehicle-card pa-0 mb-3 mb-sm-3 mb-md-0">
                  <v-carousel
                    v-if="vehicle.photos"
                    :show-arrows="false"
                    height="100%"
                  >
                    <v-carousel-item
                      v-for="photo in JSON.parse(vehicle.photos)"
                      :key="photo.id"
                    >
                      <async-image
                        :source-link="photo"
                        :fallback-image="getImageByType(vehicle)"
                        :image-attrs="{ aspectRatio: '1.331', contain: true }"
                      ></async-image>
                    </v-carousel-item>
                  </v-carousel>
                  <v-img
                    v-else
                    aspect-ratio="1.331"
                    contain
                    :src="getImageByType(vehicle)"
                  />
                  <v-col cols="12" class="pa-3 mt-3 ">
                    <h3>
                      <span v-if="vehicle.brand">
                        {{ vehicle.brand.name }}
                      </span>
                      <span v-if="vehicle.model">
                        / {{ vehicle.model.name }}
                      </span>
                      <span
                        class="text-body-1 d-block mb-2"
                        style="line-height: 0.7em"
                      >
                        {{ $t("OrSimilar") }}
                      </span>
                    </h3>
                    <v-divider dark></v-divider>
                    <async-image
                      class="reserve-btn-logo"
                      :source-link="companyCarLogo(vehicle)"
                      :image-attrs="{
                        aspectRatio: '1.7778',
                        contain: true,
                        width: 60,
                      }"
                    ></async-image>
                    <p class="mb-2 mt-1">
                      <v-tooltip
                        v-if="vehicle.num_of_seats"
                        color="lighten-1"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small v-bind="attrs" v-on="on">
                            mdi-car-seat
                          </v-icon>
                          {{ vehicle.num_of_seats }}
                          |
                        </template>
                        <span>{{ $t("NumberOfSeats") }}</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="vehicle.num_of_packages"
                        color="lighten-1"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          {{ vehicle.num_of_packages }}
                          <v-icon small v-bind="attrs" v-on="on">
                            mdi-bag-checked
                          </v-icon>
                        </template>
                        <span> {{ $t("NumberOfPackages") }} </span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="vehicle.class"
                        color="lighten-1"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          {{ vehicle.class }}
                          <v-icon small v-bind="attrs" v-on="on">
                            mdi-shape
                          </v-icon>
                          |
                        </template>
                        <span> {{ $t("Class") }} </span>
                      </v-tooltip>
                      <v-tooltip v-if="vehicle.type" color="lighten-1" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          {{ $t(vehicle.type) }}
                          <v-icon small v-bind="attrs" v-on="on">
                            mdi-car-select
                          </v-icon>
                          |
                        </template>
                        <span> {{ $t("VehicleType") }} </span>
                      </v-tooltip>
                    </p>
                    <p v-if="vehicle.transmission" class="mb-3">
                      <v-tooltip color="lighten-1" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small v-bind="attrs" v-on="on">
                            mdi-car-wrench
                          </v-icon>
                          {{ $t(vehicle.transmission) }}
                        </template>
                        <span> {{ $t("VehicleTransmission") }} </span>
                      </v-tooltip>
                    </p>
                    <div class="">
                      <div
                      >
                        <span class="ml-1 mr-1">
                          {{ $t("DailyPrice") }}:
                        </span>
                        <span class="price-text">
                          {{ Number(vehicle.rental_price_per_day).toFixed(2) }} $US
                        </span>
                      </div>
                      <br />
                      <div
                      >
                        <span class="ml-1 mr-1">
                          {{ $t("TotalPrice") }}:
                        </span>
                        <span class="price-text">
                          {{ Number(vehicle.rental_price).toFixed(2) }} $US (
                          {{ numberOfDays }} {{ $t("DayOrDays") }} )
                        </span>
                      </div>
                    </div>
                    <v-btn
                      @click="reserveVehicle(vehicle.id)"
                      color="#000"
                      depressed
                      class=" reserve-btn rounded-0 lighten-1"
                    >
                      <span style="color: #fff;">
                        <v-icon> mdi-checkbox-marked-circle-outline </v-icon>
                        {{ $t("ReserveNow") }}
                      </span>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-col>
            </v-row>
            <v-col cols="12" class="mt-10">
              <a
                v-if="!endOfResults"
                @click="infiniteScrolling()"
                id="scrollToSearch"
                href="javascript:void(0)"
              >
                <p>{{ $t("LoadMore") }}</p>
                <span></span>
              </a>
            </v-col>
          </v-col>
          <v-dialog v-model="customerInfoDialog" width="500">
            <v-card>
              <v-card-title class="text-h5  mb-10 " style="background-color: #E8EAE0">
                <p>{{ $t("WeJustNeedToKnowWhoYouAre") }}</p>
              </v-card-title>

              <v-card-text>
                <login-form :solo="false" @successfull-login="customerLoggedIn" />
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions v-show="false">
                <v-spacer></v-spacer>
                <v-btn
                  @click="reserveVehicle()"
                  color="secondary"
                  depressed
                  class=" rounded-0 reserve-btn"
                >
                  <v-icon> mdi-checkbox-marked-circle-outline </v-icon>
                  {{ $t("ReserveNow") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="completeReservationInfoDialog" width="500">
            <v-card>
              <v-card-title class="text-h5  red">
                {{ $t("WhenDoYouWantToReserveTheVehicle") }}
              </v-card-title>

              <v-form ref="reservationInfoForm">
                <v-card-text class="pt-0 pb-0">
                  <v-card-subtitle>
                    <span>
                      <v-icon>mdi-calendar</v-icon>
                      {{ $t("ReservationDate") }}
                    </span>
                  </v-card-subtitle>
                  <v-datetime-picker
                    v-model="filter.from"
                    color="#000"
                    :label="$t('ReceivingDate')"
                    :clearText="$t('Cancel')"
                    :okText="$t('Confirm')"
                    :textFieldProps="{
                      class: 'rounded-0',
                      appendIcon: 'mdi-calendar',
                      solo: true,
                      color: 'red',
                      rules: [filterRules.required],
                    }"
                    :datePickerProps="{
                      color: 'red',
                      min: dateHelpers.formatDate(new Date()),
                    }"
                    :timePickerProps="{
                      color: 'red',
                    }"
                  >
                    <template v-slot:dateIcon>
                      <v-icon color="#000">mdi-calendar</v-icon>
                    </template>
                    <template v-slot:timeIcon>
                      <v-icon color="#000">mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                  <v-datetime-picker
                    v-model="filter.to"
                    color="#000"
                    :label="$t('DeliveringDate')"
                    :clearText="$t('Cancel')"
                    :okText="$t('Confirm')"
                    :textFieldProps="{
                      vModel: filter.to,
                      class: 'rounded-0',
                      appendIcon: 'mdi-calendar',
                      solo: true,
                      color: 'red',
                      rules: [filterRules.required],
                    }"
                    :datePickerProps="{
                      color: 'red',
                      min: dateHelpers.formatDate(filter.from),
                    }"
                    :timePickerProps="{
                      color: 'red',
                    }"
                  >
                    <template v-slot:dateIcon>
                      <v-icon color="#000">mdi-calendar</v-icon>
                    </template>
                    <template v-slot:timeIcon>
                      <v-icon color="#000">mdi-clock</v-icon>
                    </template>
                  </v-datetime-picker>
                </v-card-text>
                <v-card-text class="pt-0 pb-0">
                  <v-card-subtitle
                    class="d-flex align-center justify-space-between"
                  >
                    <span>
                      <v-icon>mdi-map</v-icon>
                      {{ $t("ReceivingLocation") }}
                    </span>
                    <v-checkbox
                      class="mb-5"
                      v-model="deliverToDifferentLocation"
                      :label="$t('DeliverToDifferentLocation')"
                      color="#000"
                      hide-details
                    ></v-checkbox>
                  </v-card-subtitle>
                  <cities-autocomplete
                    :attrs="{
                      appendIcon: 'mdi-map',
                      label: $t('ReceivingLocation'),
                      color: 'red',
                      placeholder: $t('SearchLocation'),
                      rules: [filterRules.required],
                      value: this.pickupLocation,
                    }"
                    @city-selected="receivingLocationSelected"
                  >
                  </cities-autocomplete>
                  <cities-autocomplete
                    v-if="deliverToDifferentLocation"
                    :attrs="{
                      appendIcon: 'mdi-map',
                      label: $t('DeliveringLocation'),
                      color: 'red',
                      placeholder: $t('SearchLocation'),
                      rules: [filterRules.required],
                      value: this.deliveryLocation,
                    }"
                    @city-selected="deliveringLocationSelected"
                  >
                  </cities-autocomplete>
                </v-card-text>
              </v-form>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="completeReservationInfo()"
                  color="secondary"
                  depressed
                  class=" rounded-0 reserve-btn"
                >
                  <v-icon> mdi-checkbox-marked-circle-outline </v-icon>
                  {{ $t("ReserveNow") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { getVehicles } from "../../api/cars";
import { getBrands } from "../../api/brands";
import { getByDomain } from "../../api/companies";
import { getById as getCityById } from "../../api/cities";
import AsyncImage from "../../components/Util/AsyncImage";
import CitiesAutocomplete from "../../components/Form/CitiesAutoComplete";
import CountryCodePhoneInput from "../../components/Form/CountryCodePhoneInput";
import LoginForm from "../../components/Form/Auth/Login";
import DevbeyLogoLoader from "../../components/Util/DevbeyLogoLoader";
import defaultImage from "../../assets/no-car-image.png";
import hatchbackImage from "../../assets/Hatchback.png";
import minivanImage from "../../assets/Minivan.png";
import sedanImage from "../../assets/Sedan.png";
import SUVImage from "../../assets/SUV.png";

export default {
  name: "Vehicles",
  components: {
    AsyncImage,
    CitiesAutocomplete,
    CountryCodePhoneInput,
    LoginForm,
    DevbeyLogoLoader,
  },
  computed: {
    filterRules() {
      return Vue.formHelper.filterRules();
    },
    deliverLocationRules() {
      if (this.deliverToDifferentLocation) {
        return [this.filterRules.required];
      }
      return [];
    },
    isFilterPanelExpandable() {
      return this.isMobile || this.isTablet ? true : false;
    },
    filterPanelIcon() {
      return this.isMobile || this.isTablet ? "mdi-menu-down" : "";
    },
    numberOfDays() {
      let { from, to } = this.filter;
      let diffInDays = Vue.dateHelpers.differenceInDays(from, to);

      if (isNaN(diffInDays)) {
        return 0;
      }
      return diffInDays;
    },
    ...mapGetters({
      carTypes: "cars/getTypes",
      carTransmissions: "cars/getTransmissionTypes",
      isMobile: "isMobile",
      isTablet: "isTablet",
      isLoggedIn: "auth/isLoggedIn",
      profile: "profile/getProfile",
      isProfileComplete: "profile/isProfileComplete",
      company: "company/getCompany",
    }),
  },
  watch: {
    company: {
      immediate: true,
      handler(newVal, oldVal) {
        this.setCompanyLogo();
      },
    },
  },
  data() {
    return {
      receivingDateCalendarOpened: false,
      deliveringDateCalendarOpened: false,
      receivingDatePopupCalendarOpened: false,
      deliveringDatePopupCalendarOpened: false,
      searchedVehiclesByDate: false,
      customerInfoDialog: false,
      completeReservationInfoDialog: false,
      searchPanelInnerExpansions: null,
      showCompanyFilter: true,
      companyByDomain: null,
      company_id: null,
      page: 1,
      maxPrice: 99999999,
      vehicles: [],
      endOfResults: false,
      apiCall: {
        error: false,
        message: "",
        loading: false,
      },
      filter: {
        price_range: [0, 9999999],
        num_of_seats: null,
        num_of_seats: null,
        type: null,
        transmission: null,
        brand_id: null,
        from: null,
        to: null,
        receiving_location: null,
        delivering_location: null,
        sending_time: null,
        receiving_time: null,
        sortBy: null,
        sortDir: null,
        companies: null,
      },
      sortColumns: [
        { text: this.$t("Price"), value: "price_list.price" },
        { text: this.$t("Class"), value: "class" },
      ],
      sortDirections: [
        { text: this.$t("Asc"), value: "asc" },
        { text: this.$t("Desc"), value: "desc" },
      ],
      carBrands: [],
      vehicleIDPreparedForReservation: null,
      searchPanel: 0,
      deliverToDifferentLocation: false,
      pickupLocation: null,
      deliveryLocation: null,
      companyLogoLinkReady: false,
      companyLogo: null,
      selectedBrand: null,
      searchPanelAnimated: false,
      companies: []
    };
  },
  methods: {
    async searchForVehicles(passValidation = false) {

      console.log('searching');
      this.endOfResults = false;

      if (!passValidation) {
        if (!this.$refs.filterForm.validate()) {
          this.$refs.filterForm.inputs.forEach(e => {
              if (e.errorBucket && e.errorBucket.length) {
                console.log(...e.errorBucket);
              }
            },
          );
          this.searchPanelAnimated = true;
          setTimeout(() => {
            this.searchPanelAnimated = false;
          }, 1000);

          this.apiCall.loading = false;
          return;
        }
      }

      console.log('searching2');
      this.page = 1; // resetting page to first when filters apply to begin new search
      this.apiCall.loading = true;

      this.vehicles = await this.fetchData().catch((e) => {
        this.apiCall.loading = false;
      });
      setTimeout(() => {
        this.apiCall.loading = false;
      }, 500);
    },
    async fetchData() {
      let filterObj = Vue.objectHelpers.clean(this.filter);

      if (this.pickupLocation) {
        filterObj.receiving_location = this.pickupLocation.id;
      }

      if (this.deliveryLocation) {
        if (!this.deliverToDifferentLocation) {
          filterObj.delivering_location = this.pickupLocation.id;
        } else {
          filterObj.delivering_location = this.deliveryLocation.id;
        }
      }

      if (this.company_id) {
        filterObj = { ...filterObj, company_id: this.company_id };
      }
      else if (filterObj.companies && (typeof filterObj.companies == 'string' || typeof filterObj.companies == 'number'))
      {
        // to prevent sending numeric companies parameter to backened
        filterObj.companies = [filterObj.companies];
      }

      return getVehicles({
        params: {
          ...filterObj,
          deliver_to_different_location: this.deliverToDifferentLocation,
          page: this.page,
          limit: 12,
        },
      })
        .then((vehicles) => {
          this.apiCall.error = false;
          if (vehicles.data) {
            this.maxPrice = vehicles.data["maxPrice"]["USD_Price"];
            this.companies = vehicles.data["companies"];
            if (vehicles.data.data.data) return vehicles.data.data.data;
          }
          return [];
        })
        .catch((err) => {
          console.log(err);
          this.apiCall.error = true;
          switch (err.response.status) {
            case 400:
              this.apiCall.message = err.response.data.error.message;
              break;
            default:
              this.apiCall.message = this.$t("ErrorFetchingData");
          }
          return [];
        });
    },
    async infiniteScrolling() {
      this.apiCall.loading = true;
      this.page++;
      let vehicles = await this.fetchData();
      if (vehicles.length) {
        vehicles.forEach((item) => this.vehicles.push(item));
      } else {
        this.$root.$emit("notify-info", this.$t("NoMoreVehicles"));
        this.endOfResults = true;
      }
      this.apiCall.loading = false;
    },
    reserveVehicle(vehicleID) {
      //validate if customer is not logged in
      this.customerInfoDialog = false;
      if (!this.isLoggedIn) {
        this.customerInfoDialog = true;
        return;
      }

      // validate customer profile completion
      if (!this.isProfileComplete) {
        this.$root.$emit(
          "notify-error",
          this.$t("PleaseCompleteProfileInformation")
        );
        return;
      }

      // to use for completing reservation info dialog
      this.vehicleIDPreparedForReservation = vehicleID;

      // validate the required params to reserve a vehicle
      if (
        !this.filter.delivering_location ||
        !this.filter.receiving_location ||
        !this.filter.from ||
        !this.filter.to
      ) {
        // this.completeReservationInfoDialog = true;
        this.$root.$emit(
          "notify-error",
          this.$t("PleaseChooseReservationDateAndLocation")
        );
        return;
      }

      this.$secureStorage.setItem(
        "reserveFilter",
        JSON.stringify({
          ...this.filter,
          deliveryLocation: this.deliveryLocation,
          pickupLocation: this.pickupLocation,
        })
      );

      if (this.company_id) {
        this.$router.push({
          name: "Reserve",
          params: { id: vehicleID },
          query: { company_id: this.company_id },
        });
      } else {
        this.$router.push({ name: "Reserve", params: { id: vehicleID } });
      }
    },
    completeReservationInfo() {
      if (!this.$refs.reservationInfoForm.validate()) {
        return;
      }
      this.reserveVehicle(this.vehicleIDPreparedForReservation);
    },
    receivingLocationSelected(city) {
      this.pickupLocation = city;
      this.filter.receiving_location = city.id;
      if (!this.deliverToDifferentLocation) {
        this.deliveryLocation = this.pickupLocation;
        this.filter.delivering_location = this.filter.receiving_location;
      }
      this.onFilterFieldChange();
    },
    deliveringLocationSelected(city) {
      this.deliveryLocation = city;
      this.filter.delivering_location = city.id;
      this.onFilterFieldChange();
    },
    async customerLoggedIn() {
      await this.$store.dispatch("profile/show");
      this.customerInfoDialog = false;

      // BUG using this to automate process after login causing bug to navigate to / route instead of /vehicles/:id/reserve
      // this.reserveVehicle(this.vehicleIDPreparedForReservation);
    },
    onBrandFilterChange(item) {
      let id = item ? item.id : null;
      this.filter.brand_id = id;
      this.onFilterFieldChange();
    },
    onFilterFieldChange() {
      // this.searchForVehicles();
    },
    companyCarLogo(v) {
      if (v.company.logo)
        return JSON.parse(v.company.logo)[0];
      return null;
    },
    async setCompanyLogo() {
      if (this.company_id) {
        if (!this.company)
          await this.$store.dispatch("company/show", this.company_id);

        if (this.company.logo) {
          const companyLogo = JSON.parse(this.company.logo)[0];
          this.companyLogo = companyLogo;
          this.companyLogoLinkReady = true;
        }
      }
    },
    getImageByType(vehicle) {
      switch(vehicle.type) {
        case "Hatchback":
          return hatchbackImage;
        case "Minivan":
          return minivanImage;
        case "Sedan":
        case "SportsCar":
          return sedanImage;
        case "SUV":
          return SUVImage;
        default:
          return defaultImage;
      }
    },
    setUrlQueryParam(passValidation = false) {
      let searchObj = Vue.urlHelper.extractQuery();

      let query = {
        ...searchObj,
        ...this.filter,
        from: Vue.dateHelpers.formatDateTime(this.filter.from),
        to: Vue.dateHelpers.formatDateTime(this.filter.to),
        deliverToDifferentLocation: this.deliverToDifferentLocation,
      };


      query = Vue.objectHelpers.clean(query);

      if (query.companies && (typeof query.companies == 'string' || typeof query.companies == 'number'))
      {
        // to prevent sending numeric companies parameter to backened
        query.companies = [query.companies];
      }

      this.$router.push({
        query: query,
      });

      this.searchForVehicles(passValidation);
    },
    onDeliverToDifferentLocationChange() {
      if (this.deliverToDifferentLocation === false) {
        this.filter.delivering_location = this.filter.receiving_location;
        this.deliveryLocation = this.pickupLocation;
      } else {
        this.filter.delivering_location = this.deliveryLocation = null;
      }
    },
  },
  async created() {
    if (!this.profile.length) 
      await this.$store.dispatch("profile/show");
  // },
  // async mounted() {
    this.apiCall.loading = true;
    let brandsResponse = await getBrands();
    this.carBrands = brandsResponse.data.data;

    let {
      from,
      to,
      receiving_location,
      delivering_location,
      deliverToDifferentLocation,
      price_range,
      brand_id,
      transmission,
      type,
      num_of_packages,
      num_of_seats,
      sortDir,
      sortBy,
      companies,
    } = this.$route.query;
    if (!sortDir) {
      sortDir = this.sortDirections[0].value;
    }

    if (!sortBy) {
      sortBy = this.sortColumns[0].value;
    }

    let { pickupLocation, deliveryLocation } = this.$route.params;

    // to fetch receiving location data by id if set in url
    if (!pickupLocation && receiving_location) {
      let pickupLocationRespone = await getCityById(receiving_location);
      pickupLocation = pickupLocationRespone.data.data;
    }

    // to fetch delivering location data by id if set in url
    if (!deliveryLocation && delivering_location) {
      let deliveryLocationResponse = await getCityById(delivering_location);
      deliveryLocation = deliveryLocationResponse.data.data;
    }

    // to set selected brand if set in url filter
    if (brand_id) {
      let filterBrand = this.carBrands.filter((b) => b.id == brand_id)[0];
      this.selectedBrand = filterBrand;
    }

    this.searchPanelInnerExpansions = 1;

    // the use of pickupLocation & deliveryLocation is to pass the city for autocomplete comp
    // setting pickup location
    this.pickupLocation = pickupLocation || null;
    this.deliveryLocation = deliveryLocation || null;

    this.$refs.receivingLocationRef.setCity(this.pickupLocation);

    // setting delivery location depending on the deliverToDifferentLocation paramter
    if (
      deliverToDifferentLocation == "true" ||
      deliverToDifferentLocation === true
    ) {
      this.deliverToDifferentLocation = true;
    } else {
      this.deliveryLocation = pickupLocation;
      this.deliverToDifferentLocation = false;
    }
    if(companies){
      companies = Array.isArray(companies) ? [...new Set(companies.map(item=> +item))] : +companies
    }
    this.filter = {
      price_range: price_range || [0, 9999999],
      brand_id,
      transmission,
      type,
      num_of_packages,
      num_of_seats,
      receiving_location,
      delivering_location,
      sortDir,
      sortBy,
      companies,
      from: from ? new Date(from) : new Date(),
      to: to ? new Date(to) : Vue.dateHelpers.addDaysToDate(new Date(), 3),
    };
    this.apiCall.loading = false;
    const passValidation = this.filter.receiving_location && this.filter.from && this.filter.to;

    this.companyByDomain = await getByDomain();
    let searchObj = Vue.urlHelper.extractQuery();

    if (this.companyByDomain) {
      this.company_id = this.companyByDomain.id;
    }
    else if (searchObj["company_id"]) {
      this.company_id = searchObj["company_id"];
    }

    if (this.company_id) {
      this.showCompanyFilter = false;
    }

    this.setUrlQueryParam(passValidation);

    let currentCustomer = this.$secureStorage.getItem("reserveCustomer");
    if (currentCustomer) {
      Object.assign(this.customer, JSON.parse(currentCustomer));
    }
  },
};
</script>

<style scoped lang="scss">
#searchPanel {
  position: relative;
  margin-top: 25px;
  .search-card {
    position: sticky;
    top: 30px;
    max-height: 80vh;
    overflow-y: auto;
    &-header {
      position: sticky;
      top: 0;
      z-index: 9;
      background: white;
      border-bottom: 1px solid lightgrey;
    }
    .search-submit-btn {
      position: sticky;
      bottom: 0;
    }
  }
  .cars-list {
    
    .search-loader {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1;
    }
    .vehicle-card {
      border: 1px solid rgba(255, 255, 255, 0.5);
      background-color: #EEF0EB;
      position: relative;
      .price-text {
        font-weight: 900;
      }
      .price-label {
        height: 36px;
        @media (max-width: 767px) {
          position: relative;
          width: 100%;
        }
      }
      .reserve-btn {
        margin-top: 20px;
        position: relative;
        width: 100%;
      }
    }
  }
}

.reserve-btn-logo {
  position: absolute;
  right: 12px;
  background: rgba(255, 255, 255, 0.1);
}

#scrollToSearch {
  // position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  display: block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font: normal 400 12px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
  p {
    margin: 0;
  }
  &:hover {
    opacity: 0.5;
  }
  span {
    margin: auto;
    display: block;
    text-align: center;
    position: relative;
    width: 36px;
    height: 36px;
    border: 1px solid #fff;
    border-radius: 100%;
    box-sizing: border-box;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      width: 16px;
      height: 16px;
      margin: -12px 0 0 -8px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      box-sizing: border-box;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      content: "";
      width: 33px;
      height: 33px;
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
      border-radius: 100%;
      opacity: 0;
      -webkit-animation: sdb03 3s infinite;
      animation: sdb03 3s infinite;
      box-sizing: border-box;
    }
    @-webkit-keyframes sdb03 {
      0% {
        opacity: 0;
      }
      30% {
        opacity: 1;
      }
      60% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1);
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes sdb03 {
      0% {
        opacity: 0;
      }
      30% {
        opacity: 1;
      }
      60% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1);
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
</style>

<style lang="scss">
.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}
.v-application--is-rtl {
  #searchPanel {
    .cars-list {
      .vehicle-card {
        .price-label,
        .reserve-btn {
          left: 0;
          right: auto;
        }
      }
    }
  }
  .reserve-btn-logo {
    left: 12px;
    right: auto;
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.banner {
  width: 100%;
}

.filter-panel {
  background-color: #EEF0EB;
}

.filter-panel-header {
  text-align: center;
  padding: 20px;
  font-size: 20px;
  font-weight: 900;
}

.filter-panel-content {
  padding: 10px;
  padding-bottom: 20px;
}
.filter-panel-content-header {
  margin-bottom: 10px;
}

</style>