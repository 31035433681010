import axios from 'axios';

async function getVehicles(filter = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`cars/non-reserved`, {...filter })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function showVehicle(id, filter = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`cars/${id}?to=${ filter?.to }&from=${ filter?.from }`, {...filter })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function loadImage(path) {
    return await new Promise((resolve, reject) => {
        axios.get('assets/base64?url=' + path)
            .then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            })
    });
}

export {
    getVehicles,
    showVehicle,
    loadImage,
}